html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font:
    300 11px/1.4 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #252525;
  overflow: hidden;
}
